<template>
  <div class="flex flex-1">
    <div class="w-[12rem] flex flex-col gap-2 px-5 py-10">
      <div class="flex items-center justify-center">
        <fw-icon-uc-digital-building class="w-20 h-20 text-white " />
      </div>
      <div v-if="currentHour" class="flex flex-col pl-2 gap-1 items-center text-center whitespace-nowrap font-bold">
        <div class="flex gap-1 font-bold items-center text-2xl">
          <span>{{ currentHour }}</span>
          <span class="animate-pulse">:</span>
          <span>{{ currentMinutes }}</span>
        </div>
      </div>
      <div class="flex flex-col gap-1 text-center pt-3 mt-2 border-t border-gray-700">
        <div class="opacity-30 font-bold text-xs">ID da sala</div>
        <v-clamp autoresize :max-lines="1" class="font-mono text-lg font-semibold">
          {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
        </v-clamp>
        <v-clamp autoresize :max-lines="2" class="font-semibold text-xs text-gray-400">
          {{ meetingTitle }}
        </v-clamp>
        <v-clamp v-if="meeting.title != meetingTitle" autoresize :max-lines="1" class="font-bold text-sm">
          {{ meeting.title }}
        </v-clamp>
      </div>
      <div class="flex-1"></div>
      <div class="flex flex-col gap-5">
        <div class="flex flex-col items-center gap-1 justify-center text-center">
          <div class="text-base text-white opacity-30">Vídeo</div>
          <div
            class="flex gap-3 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }"
          >
            <fw-icon-vidicon-solid class="w-10 h-10 flex-shrink-0" />
          </div>
          <div class="text-sm font-semibold" :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }">
            {{ withVideo ? 'Ligado' : 'Desligado' }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div class="text-base text-white opacity-30">Microfone</div>
          <div
            class="flex gap-2 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': withMic, 'text-red-600': !withMic }"
          >
            <fw-icon-mic v-if="withMic" class="w-10 h-10 flex-shrink-0" />
            <fw-icon-mic-off v-else class="w-10 h-10 flex-shrink-0" />
          </div>
          <div class="text-sm font-semibold" :class="{ 'text-primary': withMic, 'text-red-600': !withMic }">
            {{ withMic ? 'Ligado' : 'Desligado' }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div class="text-base text-white opacity-30">MONITOR 1</div>
          <div
            class="flex gap-3 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': screen1, 'opacity-50': !screen1 }"
          >
            <fw-icon-screen class="w-10 h-10 flex-shrink-0" />
            1
          </div>
          <div class="text-sm font-semibold" :class="{ 'text-primary': screen1, ' text-white opacity-30': !screen1 }">
            {{ screen1 ? 'A partilhar' : 'Não partilhado' }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div class="text-sm text-white opacity-30">MONITOR 2</div>
          <div
            class="flex gap-2 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': screen2, 'opacity-50': !screen2 }"
          >
            <fw-icon-screen class="w-10 h-10 flex-shrink-0" />
            2
          </div>
          <div class="text-sm font-semibold" :class="{ 'text-primary': screen2, ' text-white opacity-30': !screen2 }">
            {{ screen2 ? 'A partilhar' : 'Não partilhado' }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div class="text-sm text-white opacity-30">MONITOR 2</div>
          <div
            class="flex gap-2 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': screen2, 'opacity-50': !screen2 }"
          >
            <fw-icon-screen class="w-10 h-10 flex-shrink-0" />
            2
          </div>
          <div class="text-sm font-semibold" :class="{ 'text-primary': screen2, ' text-white opacity-30': !screen2 }">
            {{ screen2 ? 'A partilhar' : 'Não partilhado' }}
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div
            class="flex gap-2 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': handsupUnatended > 0, 'opacity-50': handsupUnatended == 0 }"
          >
            <fw-icon-hand-raised2 class="w-8 h-8 flex-shrink-0" />
            <span>{{ handsupUnatended }}</span>
          </div>
          <div
            class="text-sm font-semibold"
            :class="{ 'text-primary': handsupUnatended > 0, ' text-white opacity-30': handsupUnatended == 0 }"
          >
            A pedir a palavra
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div
            class="flex gap-2 items-center text-4xl font-extrabold"
            :class="{ 'text-primary': askingToEnter > 0, 'opacity-50': askingToEnter == 0 }"
          >
            <fw-icon-door class="w-8 h-8 flex-shrink-0" />
            <span>{{ askingToEnter }}</span>
          </div>
          <div
            class="text-sm font-semibold"
            :class="{ 'text-primary': askingToEnter > 0, ' text-white opacity-30': askingToEnter == 0 }"
          >
            A pedir para entrar
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 justify-center text-center">
          <div
            class="flex gap-2 items-center text-4xl font-extrabold"
            :class="{ 'text-white': numberAttendes > 0, 'opacity-50': numberAttendes == 0 }"
          >
            <fw-icon-map-pin-user class="w-10 h-10 flex-shrink-0" />
            <span>{{ numberAttendes }}</span>
          </div>
          <div
            class="text-sm font-semibold"
            :class="{ 'text-white': numberAttendes > 0, ' text-white opacity-30': numberAttendes == 0 }"
          >
            Online
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex-1 bg-black grid grid-rows-2 gap-3 p-3">
      <div class="grid grid-cols-2 gap-2">
        <div class="relative">
          <BlockRemoteStreamScreen
            v-if="myPod"
            :key="myPod.key"
            :main="false"
            :ignore-fullscreen="true"
            :pod="myPod"
            :pods-length="1"
            :attendee="attendee"
            :with-fullscreen="false"
            :as-webinar="false"
            :fill-pod="true"
            :borderless="false"
            size="md"
            :show-name="false"
            :show-hd="true"
          />
        </div>
        <div class="p-1">
          <div
            class="video-wrapper cursor-pointer w-full h-full z-0 bg-gray-900 remote-pod group relative rounded-2xl overflow-hidden"
          ></div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-6 gap-2">
          <template v-for="pod in pageActivePods.slice(0, 18)">
            <BlockRemoteStreamScreen
              v-if="pod.id != myPod.id"
              :key="pod.id"
              :pod="pod"
              :pods-length="pageActivePods.length"
              :attendee="attendee"
              :as-webinar="false"
              :fill-pod="true"
              size="md"
              :show-name="true"
              :show-hd="false"
              :show-guest-info="true"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockRemoteStreamScreen from '@/components/blocks/BlockRemoteStreamScreen'

export default {
  name: 'PanelMeetingInfo',
  components: {
    BlockRemoteStreamScreen
  },
  props: {
    activePods: {
      type: Array,
      default: () => []
    },
    pods: {
      type: Array,
      default: () => []
    },
    meeting: {
      type: Object,
      default: () => {}
    },
    attendees: {
      type: Object,
      default: () => {}
    },
    waitingAttendees: {
      type: Array,
      default: () => []
    },
    attendeesHandsUp: {
      type: Array,
      default: () => []
    },
    attendee: {
      type: Object
    },
    asWebinar: {
      type: Boolean,
      default: false
    },
    duration: {
      type: String,
      default: '--:--:--'
    },
    isClassEdition: {
      type: Boolean,
      default: false
    },
    freeMicrophones: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      meetingStartTime: '--:--',
      currentHour: null,
      currentMinutes: null,
      maxHeightVideo: '740'
    }
  },
  computed: {
    withMic() {
      if (this.myPod) {
        return this.myPod.withAudio
      } else {
        return false
      }
    },
    withVideo() {
      if (this.myPod) {
        return this.myPod.video != null
      } else {
        return false
      }
    },
    meetingTitle() {
      return !this.isClassEdition ? this.meeting.title : this.meeting.context.unit.title
    },
    askingToEnter() {
      return this.waitingAttendees.length
    },
    numberAttendes() {
      // Do not count the host
      return Math.max(0, this.pods.filter(i => i.type == 'attendee' && !i.isUcMiddleware).length - 1)
    },
    attendeesWithaudioPermission() {
      return this.attendeesHandsupHidrated.filter(attendee => attendee.withRole('audio_allowed')).length
    },
    handsupUnatended() {
      return this.attendeesHandsUp.length
    },
    attendeesHandsupHidrated() {
      return this.attendeesHandsUp.map(attendeeId => {
        return this.attendees[attendeeId]
      })
    },
    myPod() {
      return this.activePods.length > 0 ? this.activePods[0] : null
    },
    screenPods() {
      return this.pods.filter(pod => pod.type == 'screen_share')
    },
    screenPodsLenght() {
      return this.screenPods.length
    },
    screen1() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('primário') ||
            element.name.toLowerCase().includes('primary') ||
            element.name.toLowerCase().includes('principal')
        ) != 'undefined'
      )
    },
    screen2() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('secundário') || element.name.toLowerCase().includes('secondary')
        ) != 'undefined'
      )
    },
    pageActivePods() {
      // if (this.isSharingScreen || (this.activePods.length > 0 && this.activePods[0].isUcMiddleware)) {
      //   return this.activePods.slice(1)
      // }
      if (this.activeScreenPod) {
        return this.activePods.filter(pod => pod !== this.activeScreenPod)
      }
      return this.activePods
    }
  },
  created() {
    // this.maxHeightVideo = window.innerHeight - 340
  },
  mounted() {
    this.startClock()
  },
  methods: {
    updateTime() {
      let date = new Date()
      this.currentHour = date.toLocaleTimeString('pt-PT', {
        hour: '2-digit'
      })
      this.currentMinutes = this.checkSingleDigit(
        date.toLocaleTimeString('pt-PT', {
          minute: '2-digit'
        })
      )
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
    startClock() {
      this.updateTime()
      if (this.meetingStartTime == '--:--') this.meetingStartTime = this.currentHour + ':' + this.currentMinutes
      setInterval(() => {
        this.updateTime()
      }, 1000)
    }
  }
}
</script>
