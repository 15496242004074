<template>
  <LayoutDefault full :header="false" :main-sidebar="false" :tapbar="false" back-to="/" dark>
    <template #main>
      <div v-if="room" class="flex flex-col w-full items-center bg-cover bg-center bg-black">
        <div class="absolute top-5 right-5">
          <div
            v-if="joinErrorMessage"
            class="m-10 font-bold text-red-600 opacity-75 bg-red-500 bg-opacity-20 p-5 text-center"
          >
            Error: {{ joinErrorMessage }}
          </div>
          <div v-else class="flex items-center justify-end p-10 opacity-90">
            <fw-dot color="white" wh="h-7 w-7" inline />
          </div>
        </div>
        <div class="flex-1"></div>
        <div v-if="showWideScreen" class="w-full h-full flex items-center">
          <div class="w-[36%] p-32 uppercase bg-white text-black h-full flex items-center">
            <div class="flex gap-20">
              <div>
                <div class="font-black text-[6.25rem] leading-[7.5rem]">
                  <div>Bem-vindo/a</div>
                </div>
                <div class="text-[3.25rem] font-bold leading-[4.5rem]">
                  <div>Ao novo Anfiteatro Híbrido</div>
                  <div>da Universidade de Coimbra</div>
                </div>
              </div>
              <div>
                <fw-icon-uc-digital-building class="w-72 h-72 flex-shrink-0 text-black" />
              </div>
            </div>
          </div>
          <div class="w-[47%] p-32 uppercase h-full flex items-center bg-[#53247f] text-white">
            <div class="font-black text-[5rem] flex flex-col gap-10">
              <div class="font-normal text-[3rem] uppercase leading-[2.75rem] mb-5 mt-10">Congresso</div>
              <div class="font-black text-[7.25rem] uppercase leading-[4.75rem]">Portugal-Brasil</div>
              <div class="text-[3.25rem] font-bold uppercase leading-[4rem]">
                Convergência Regulatória – Caminhos e perspetivas para harmonização dos princípios e normas de
                Vigilância Sanitária
              </div>
              <div class="text-[1.5rem] font-bold mt-10">Coimbra, 10 a 12 de Abril de 2024</div>
            </div>
          </div>
          <div class="w-[17%] p-32 uppercase bg-black text-white h-full gap-40 flex items-center justify-center">
            <div class="flex items-center justify-center">...</div>
          </div>
        </div>
        <div v-else>
          <div class="flex items-center justify-center flex-shrink-0">
            <fw-icon-uc-digital-building class="w-96 h-96 text-white" />
          </div>
        </div>
        <div class="flex-1"></div>
        <div v-if="showMeetingInfo" class="font-semibold text-2xl mt-5">Sala fechada</div>
        <div class="flex gap-5 w-full px-10 py-3 opacity-0 hover:opacity-80 text-white absolute bottom-0">
          <div><b>Key:</b> {{ room.key }}</div>
          <div><b>Name:</b> {{ room.name }}</div>
          <div class="flex-1"></div>
          <div>
            <fw-button type="link-danger" @click.native="unsubscribeRoom">Remove room</fw-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div><b>Subscribe meeting room</b></div>
        <form>
          <div class="label">Key</div>
          <b-input v-model="subscribeKey" :disabled="loadingRoom" />
          <div class="label">Token</div>
          <b-input v-model="subscribeToken" :disabled="loadingRoom" />
          <div class="label">Name</div>
          <b-input v-model="subscribeName" :disabled="loadingRoom" />
          <div v-if="errorMessage">{{ errorMessage }}</div>
          <b-button :disabled="loadingRoom || !validForm" :loading="loadingRoom" @click="subscribeRoom">
            Subscribe
          </b-button>
        </form>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
//import AppLogo from '@/components/AppLogo'

export default {
  components: {
    LayoutDefault
    //AppLogo,
  },

  data() {
    return {
      subscribeKey: '',
      subscribeToken: '',
      subscribeName: '',
      room: ServiceMeetings.getSubscribedRoom(),
      validForm: false,
      loadingRoom: false,
      wsSubscribed: false,
      errorMessage: null,
      joinErrorMessage: null
    }
  },

  computed: {
    showMeetingInfo() {
      return localStorage.getItem('show-info') == 'true'
    },
    showWideScreen() {
      return localStorage.getItem('show-widescreen') == 'true'
    }
  },

  watch: {
    subscribeKey() {
      this.checkSubscribeRoom()
    },
    subscribeToken() {
      this.checkSubscribeRoom()
    },
    subscribeName() {
      this.checkSubscribeRoom()
    }
  },

  beforeMount() {
    if (this.room) {
      console.log('Active room, going to subscribe', this.room)
      this.subscribeKey = this.room.key
      this.subscribeToken = this.room.token
      this.subscribeName = this.room.name
      this.subscribeRoomCore(this.room.key, this.room.token)
    }
  },
  beforeDestroy() {
    if (this.wsSubscribed) {
      this.$store.commit('unsubscribeWS', { code: 'meetings', name: 'MeetingRoom' })
      this.$store.commit('unsubscribeWS', { code: 'ws-connection', name: 'MeetingRoomReconnect' })
    }
  },

  methods: {
    clearForm() {
      this.subscribeKey = ''
      this.subscribeToken = ''
      this.subscribeName = ''
      this.validForm = false
      this.errorMessage = null
    },
    async setWSNotificationsMessages(messages) {
      let joinMeetingKey
      if (messages.subscribe_room) {
        for (let message of messages.subscribe_room) {
          const errorMessage = utils.errors(message).getTranslated()
          if (errorMessage) {
            this.errorMessage = errorMessage
          } else {
            if (this.subscribeKey) {
              this.room = ServiceMeetings.subscribeRoomRegister(
                this.subscribeKey,
                this.subscribeToken,
                this.subscribeName
              )
              this.clearForm()

              if (message.meeting_key) {
                joinMeetingKey = message.meeting_key
              }
            } else {
              this.errorMessage = 'required key'
            }
          }
        }
        this.loadingRoom = false
      }

      if (messages.unsubscribe_room) {
        ServiceMeetings.subscribeRoomDeregister()
        this.clearForm()
        this.room = null

        this.$store.commit('unsubscribeWS', { code: 'meetings', name: 'MeetingRoom' })
        this.wsSubscribed = false
        this.loadingRoom = false
      }

      if (messages.roomStart) {
        for (let message of messages.roomStart) {
          joinMeetingKey = message.meeting_key
          console.debug('Got meeting on room', joinMeetingKey)
        }
      }

      if (joinMeetingKey) {
        try {
          const errorResponse = await ServiceMeetings.authAnonymous(joinMeetingKey, this.room.name, { room: this.room })
          if (errorResponse) {
            const errors = utils.errors(errorResponse).setContext('meeting')
            this.joinErrorMessage = errors.getFieldTranslated('name')
          } else {
            this.$router.push({
              name: 'live',
              params: { key: joinMeetingKey }
            })
            return
          }
        } catch (error) {
          this.joinErrorMessage = 'Ocorreu um erro, vamos tentar novamente.'
          setTimeout(() => {
            window.location.reload(true)
          }, 2500)
        }
      }
    },
    async checkSubscribeRoom() {
      this.validForm =
        this.subscribeKey.length >= 8 && this.subscribeToken.length >= 8 && this.subscribeName.length >= 2
    },
    async unsubscribeRoom() {
      if (this.loadingRoom) return
      this.loadingRoom = true
      await ServiceMeetings.unsubscribeRoom()
    },
    async subscribeRoom() {
      await this.subscribeRoomCore(this.subscribeKey, this.subscribeToken)
    },
    async wsRoomConnection(event) {
      if (event === 'ended') {
        this.loadingRoom = false
        this.wsSubscribed = false
      } else if (this.room) {
        console.log('WS connected. Active room, going to subscribe', this.room)
        this.subscribeKey = this.room.key
        this.subscribeToken = this.room.token
        this.subscribeName = this.room.name
        this.subscribeRoomCore(this.room.key, this.room.token, true)
      }
    },
    async subscribeRoomCore(key, token, force = false) {
      if (!force && this.loadingRoom) return
      this.loadingRoom = true

      this.$store.commit('subscribeWS', {
        code: 'meetings',
        name: 'MeetingRoom',
        callback: this.setWSNotificationsMessages
      })
      this.$store.commit('subscribeWS', {
        code: 'ws-connection',
        name: 'MeetingRoomReconnect',
        callback: this.wsRoomConnection
      })
      this.wsSubscribed = true

      this.errorMessage = null
      await ServiceMeetings.subscribeRoom(key, token)
    }
  }
}
</script>
