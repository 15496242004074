var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute top-0 left-0 right-0 bottom-0 z-40 flex overflow-hidden"},[_c('div',{ref:"share-screen-container",staticClass:"flex-1 flex flex-col overflow-hidden flex-shrink-0 relative"},[(_vm.activeScreenPod)?_c('div',{key:_vm.activeScreenPod.key,staticClass:"overflow-hidden w-full px-3 pt-3",style:({ height: _vm.screenHeight + 'px' })},[_c('BlockRemoteStreamScreen',{key:_vm.activeScreenPod.id,attrs:{"attendee":_vm.attendee,"pod":_vm.activeScreenPod,"with-fullscreen":false,"as-webinar":false,"fill-pod":true,"size":"md"}})],1):_vm._e(),_c('div',{staticClass:"absolute justify-start items-start left-0 right-0 grid",class:{
        'h-screen': !_vm.isSharingScreen,
        [_vm.gridClass]: !_vm.isSharingScreen,
        'bottom-3 left-3 grid-rows-1 grid-cols-12 gap-2': _vm.isSharingScreen
      },style:({ top: (_vm.isSharingScreen ? _vm.screenHeight : 0) + 'px' })},_vm._l((_vm.pageActivePods),function(pod){return _c('div',{key:pod.id,staticClass:"overflow-hidden w-full",class:{ absolute: pod.fullscreen },style:({ maxHeight: _vm.maxPodHeight, height: _vm.height })},[_c('BlockRemoteStreamScreen',{key:pod.id,attrs:{"pod":pod,"pods-length":_vm.podsLength,"attendee":_vm.attendee,"with-fullscreen":_vm.withFullscreen,"as-webinar":false,"fill-pod":true,"size":_vm.isSharingScreen ? 'xs' : 'md-2',"show-name":!_vm.isSharingScreen,"show-hd":true,"show-guest-info":false},on:{"toggle-fullscreen":_vm.toggleFullscreen}})],1)}),0)]),_c('div',{staticClass:"flex flex-col gap-3 items-center py-4 pr-4 pl-3 w-56"},[_c('div',{staticClass:"flex flex-col gap-5 flex-1 pt-10"},[(_vm.currentHour)?_c('div',{staticClass:"flex flex-col gap-2 items-center text-center whitespace-nowrap text-5xl font-bold text-white z-50"},[_c('div',{staticClass:"flex font-bold items-center"},[_vm._v(" "+_vm._s(_vm.currentHour)+" "),_c('span',{staticClass:"animate-pulse"},[_vm._v(":")]),_vm._v(" "+_vm._s(_vm.currentMinutes)+" ")])]):_vm._e(),_c('div',{staticClass:"flex flex-col gap-4 text-center"},[_c('v-clamp',{staticClass:"font-mono opacity-70 text-xl font-semibold",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.meeting.alias && _vm.meeting.alias.length ? _vm.meeting.alias[0] : _vm.meeting.key)+" ")])],1)]),_c('div',{staticClass:"rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full h-44 relative",class:{
        'bg-primary': _vm.handsupUnatended > 0,
        'bg-gray-800 opacity-20': _vm.handsupUnatended == 0
      }},[_c('fw-icon-hand-raised2',{staticClass:"w-12 h-12 absolute top-5 left-5"}),_c('div',{staticClass:"text-6xl font-black"},[_vm._v(" "+_vm._s(_vm.handsupUnatended)+" ")]),_c('div',{staticClass:"text-md font-bold text-center absolute bottom-5 left-5 right-5"},[_vm._v("A pedir a palavra")])],1),_c('div',{staticClass:"rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full h-44 relative",class:{
        'bg-primary animate-pulse': _vm.askingToEnter > 0,
        'bg-gray-800 opacity-20': _vm.askingToEnter === 0
      }},[_c('fw-icon-door',{staticClass:"w-12 h-12 absolute top-5 left-5"}),_c('div',{staticClass:"text-6xl font-black"},[_vm._v(" "+_vm._s(_vm.askingToEnter)+" ")]),_c('div',{staticClass:"text-md font-bold text-center absolute bottom-5 left-5 right-5"},[_vm._v("A pedir para entrar")])],1),_c('div',{staticClass:"rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full h-44 bg-gray-800 relative opacity-20"},[_c('fw-icon-map-pin-user',{staticClass:"w-12 h-12 absolute top-5 left-5"}),_c('div',{staticClass:"text-6xl font-black"},[_vm._v(" "+_vm._s(_vm.numberAttendes)+" ")]),_c('div',{staticClass:"text-md font-bold text-center absolute bottom-5 left-5 right-5"},[_vm._v("Online")])],1),(_vm.totalScreenPods.length > 1)?_c('div',{staticClass:"rounded-xl px-5 py-3 flex w-full items-center gap-5 bottom-0 text-md right-0 bg-yellow-400 text-black font-bold"},[_c('svg',{staticClass:"h-10 w-10 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"}})]),_vm._v(" "+_vm._s(_vm.totalScreenPods.length)+" partilhas remotas ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }