<template>
  <div
    :id="pod.id"
    class="remote-pod group relative"
    :class="{
      'border-8': !borderless && size == 'lg' && !pod.fullscreen,
      'border-4': !borderless && size != 'lg' && !pod.fullscreen,
      'fullscreen-pod': !ignoreFullscreen && pod.fullscreen && pod.video?.type !== 'screen_share',
      'off--is-floating': isFloating,
      'off--not-floating': !isFloating,
      relative: !pod.fullscreen,
      talking: pod.talking,
      'is-screen': pod.video && (pod.video.type === 'screen_share' || isPlugin || fillPod === false),
      'no-video': !pod.video,
      'border-transparent': !pod.talking || main,
      'border-primary': pod.talking && !main,
      'rounded-2xl overflow-hidden': !main && size != 'xs' && !pod.fullscreen,
      'rounded-xl overflow-hidden': !main && size == 'xs' && !pod.fullscreen
    }"
  >
    <!-- No video -->
    <div
      v-if="!pod.video && !main"
      class="flex flex-1 items-center text-center flex-col justify-center cursor-pointer absolute h-full w-full top-0 z-10"
      @click="$emit('toggle-fullscreen', pod)"
    >
      <v-clamp
        autoresize
        :max-lines="2"
        class="name m-2 font-bold"
        :class="{
          'text-5xl': size == 'lg',
          'text-3xl': size == 'md',
          'text-2xl': size == 'md-2'
        }"
        >{{ pod.name }}</v-clamp
      >
      <div
        v-if="!isFloating && pod.attendee && !pod.attendee.user.key && showGuestInfo"
        class="font-medium opacity-50"
        :class="{
          'text-sm': size == 'xs' || size == 'sm',
          'text-md': size != 'xs'
        }"
      >
        Convidado
      </div>
    </div>

    <div
      v-if="!pod.video && main"
      class="flex flex-1 items-center flex-col justify-center absolute h-full w-full top-0 z-10 text-red-600 text-9xl"
    >
      <faicon class="w-32 h-32" icon="video-slash"></faicon>
    </div>

    <!-- More options -->
    <div
      v-if="!main"
      class="absolute right-0 z-10 flex justify-end items-center w-full"
      :class="{ 'p-3': size === 'sm', 'p-1': size === 'xs', 'p-5': size !== 'sm' && size !== 'xs' }"
    >
      <div>
        <div
          v-if="!pod.withAudio && pod.type != 'screen_share' && size == 'lg'"
          class="p-3 text-white bg-red-500 rounded-full"
        >
          <fw-icon-mic-off class="w-8 h-8" />
        </div>
        <div
          v-else-if="!pod.withAudio && pod.type != 'screen_share' && (size == 'md' || size == 'md-2')"
          class="p-2 text-white bg-red-500 rounded-full"
        >
          <fw-icon-mic-off class="w-5 h-5" />
        </div>
        <div
          v-else-if="!pod.withAudio && pod.type != 'screen_share' && (size == 'sm' || size == 'xs')"
          class="p-1 text-white bg-red-500 rounded-full"
        >
          <fw-icon-mic-off class="w-4 h-4" />
        </div>
      </div>
    </div>

    <div
      v-if="main && !pod.withAudio"
      class="bg-red-500 bg-opacity-50 font-semibold text-white text-6xl absolute bottom-0 left-0 right-0 py-6 z-50 text-center"
    >
      O seu microfone está desligado
    </div>

    <!-- Disconnected -->
    <div
      v-if="!main && pod.attendee && pod.attendee.status == 'disconnected'"
      class="text-red-600 absolute  p-1 z-10"
      :class="{ 'bottom-4 right-4': size != 'xs', 'bottom-1 right-1': size == 'xs' }"
    >
      <fw-icon-offline :class="{ 'w-8 h-8': size != 'xs', 'w-4 h-4': size == 'xs' }" />
    </div>

    <!-- Pod info -->
    <div
      v-else-if="!main"
      class="text-sm gap-2 z-10 absolute"
      :class="{
        hidden: pod.type == 'screen_share',
        'absolute top-2 left-2 p-1': isFloating,
        'bottom-0 left-0 p-1': size == 'lg',
        'bottom-2 left-2 p-1': size == 'md' || size == 'md-2',
        'bottom-0.5 left-0.5': size == 'sm' || size == 'xs'
      }"
    >
      <div
        v-if="pod.attendee && pod.video && showName"
        class="bg-black bg-opacity-30 text-white backdrop-blur group-hover:bg-opacity-50 overflow-hidden w-full flex justify-between items-center"
        :class="{
          'text-4xl py-0.5 px-4 gap-5 h-16': size == 'lg',
          'text-2xl py-0 px-2 gap-2 h-12': size == 'md',
          'text-2xl py-0 px-2 gap-2 h-12': size == 'md-2',
          'text-xs p-1 gap-2': size == 'sm',
          'text-xs px-1 py-0 gap-1': size == 'xs'
        }"
      >
        <div class="flex gap-1">
          <v-clamp
            v-if="pod.attendee && pod.video"
            autoresize
            :max-lines="1"
            class="flex-1 font-semibold tracking-tight"
            :class="{ hidden: isFloating }"
            >{{ pod.name }}</v-clamp
          >
          <div v-if="pod.attendee && pod.video && pod.attendee.with_hd && showHd" :class="{ hidden: isFloating }">
            <fw-icon-hd class="w-7 h-7" />
          </div>
        </div>
        <div class="flex gap-2 items-center text-4xl">
          <div v-if="pod.attendee && pod.attendee.with_issues">
            <svg
              class="fill-current w-4 text-red-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0H24V24H0z" />
              <path
                d="M12 3c4.284 0 8.22 1.497 11.31 3.996l-1.257 1.556C19.306 6.331 15.808 5 12 5c-3.089 0-5.973.875-8.419 2.392L12 17.817l6-7.429v3.183L12 21 .69 6.997C3.78 4.497 7.714 3 12 3zm10 16v2h-2v-2h2zm0-9v7h-2v-7h2z"
              />
            </svg>
          </div>
          <div
            v-if="pod.attendee && pod.attendee.plugin"
            class="opacity-60 group-hover:opacity-100"
            :class="{ hidden: isFloating }"
          >
            <svg
              class="fill-current w-4 hide-on-minimal"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M13 18v2h6v2h-6a2 2 0 0 1-2-2v-2H8a4 4 0 0 1-4-4V7a1 1 0 0 1 1-1h3V2h2v4h4V2h2v4h3a1 1 0 0 1 1 1v7a4 4 0 0 1-4 4h-3zm-5-2h8a2 2 0 0 0 2-2v-3H6v3a2 2 0 0 0 2 2zm10-8H6v1h12V8zm-6 6.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
          </div>
          <div
            v-if="pod.attendee && isPromoted"
            class="opacity-60 group-hover:opacity-100"
            :class="{ hidden: isFloating }"
          >
            <svg class="fill-current w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M2 19h20v2H2v-2zM2 5l5 3.5L12 2l5 6.5L22 5v12H2V5zm2 3.841V15h16V8.841l-3.42 2.394L12 5.28l-4.58 5.955L4 8.84z"
              />
            </svg>
          </div>
          <div
            v-if="pod.attendee && pod.video && pod.attendee.with_low_quality"
            class="text-base font-medium opacity-60 group-hover:opacity-100"
            :class="{ hidden: isFloating }"
          >
            Q. Baixa
          </div>
          <div
            v-if="pod.attendee && !pod.attendee.user.key && pod.video && showGuestInfo"
            class="text-base font-medium opacity-60 group-hover:opacity-100"
            :class="{ hidden: isFloating }"
          >
            Convidado
          </div>
        </div>
      </div>
    </div>

    <!-- Video paused -->
    <div v-if="!isFloating && pod.video && !pod.video.enabled" class="text-sm font-medium opacity-60 z-10">
      O vídeo foi colocado em pausa
    </div>

    <div
      :id="'video-' + pod.id"
      class="video-wrapper cursor-pointer w-full h-full z-0 bg-gray-900 max-h-full"
      @click="$emit('toggle-fullscreen', pod)"
    ></div>

    <div class="stats text-xs text-opacity-50 absolute p-3"></div>
  </div>
</template>

<script>
export default {
  name: 'BlockRemoteStream',

  props: {
    asWebinar: Boolean,
    pod: Object,
    podSize: Object,
    podsLength: Number,
    attendee: Object,
    withFullscreen: Boolean,
    ignoreFullscreen: Boolean,
    fillPod: {
      type: Boolean,
      default: true
    },
    main: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    },
    showHd: {
      type: Boolean,
      default: true
    },
    showName: {
      type: Boolean,
      default: true
    },
    showGuestInfo: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isPromoted() {
      return this.canPromotePod(this.pod) && this.asWebinar && this.pod.attendee.withRole('show_to_everyone')
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    isFloating() {
      // return this.withFullscreen && !this.pod.fullscreen
      return false
    },
    isPlugin() {
      return this.pod.attendee && this.pod.attendee.plugin
    }
  },

  mounted() {
    this.pod.mounted()
  },
  beforeDestroy() {
    this.pod.beforeDestroy()
  },

  methods: {
    canPromotePod(pod) {
      return (
        !this.$device.isMobile() &&
        pod.attendee &&
        pod.attendee.canBePromoted() &&
        this.attendee.withRole('can_promote')
      )
    },
    toogleEpandedStream() {
      this.fillPod = !this.fillPod
    }
  }
}
</script>

<style lang="scss">
.remote-pod {
  @apply w-full h-full flex flex-col items-center;

  &.fullscreen-pod {
    @apply w-full h-full fixed left-0 top-0 z-0;
  }
}
// .meetings-streams-grid .fullscreen-on {
//   // width: calc(100% + 50px);
//   width: 100%;
// }
.remote-pod {
  .video-wrapper video {
    @apply w-full h-full object-cover;
  }
  &.is-screen {
    .video-wrapper {
      video {
        @apply w-full h-full object-contain;
      }
    }
  }
}
</style>
