<template>
  <div class="w-full h-full flex">
    <!-- Pods -->
    <div v-if="podsWithVideo.length > 0" class="w-full flex flex-col">
      <div
        class="grid gap-2 h-screen"
        :class="['grid-' + gridLength, 'grid-cols-' + gridCols, 'grid-rows-' + gridRows]"
      >
        <div v-for="pod in podsWithVideo" :key="pod.id" class="overflow-hidden flex-1 shrink-0">
          <BlockRemoteStreamScreen
            :key="pod.id"
            :pod="pod"
            :pods-length="podsLength"
            :attendee="attendee"
            :with-fullscreen="withFullscreen"
            :as-webinar="asWebinar"
            :fill-pod="true"
            @mute-attendee="muteAttendee"
            @kick-attendee="kickAttendee"
            @toggle-fullscreen="toggleFullscreen"
            @toggle-pod-video="togglePodVideo"
            @toggle-promote="togglePromote"
            @go-to-user-chat="goToUserChat"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="h-full flex-1 flex"
      :class="{
        'flex-col': isVerticalScreen
      }"
    >
      <div
        class="flex flex-1 flex-col items-center justify-center w-3/5 mx-auto text-center empty-room transition-all duration-300 delay-300"
      >
        <fw-icon-uc-digital-building class="w-80 h-80 text-gray-500" />
      </div>
      <div class="flex-1 rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center relative">
        <fw-icon-map-pin-user class="w-20 h-20 absolute top-5 left-5" />
        <div class="text-8xl font-black">
          {{ numberAttendes }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas online</div>
      </div>
      <div class="flex-1 flex justify-center items-center">
        <div v-if="talkingNames" class="text-primary flex gap-2 items-center">
          <div>
            <fw-icon-speaker class="w-5 h-5 flex-shrink-0" />
          </div>
          <div>
            <v-clamp autoresize :max-lines="1">{{ talkingNames }}</v-clamp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockRemoteStreamScreen from '@/components/blocks/BlockRemoteStreamScreen'

export default {
  name: 'PanelParticipantsScreenOnlyVideo',

  components: {
    BlockRemoteStreamScreen
  },

  props: {
    pods: {
      type: Array,
      required: true
    },
    withJanus: Boolean,
    fillPod: Boolean,
    attendee: Object,
    withFullscreen: Boolean,
    asWebinar: Boolean
  },

  computed: {
    numberAttendes() {
      return this.pods.filter(i => i.type == 'attendee' && !i.isUcMiddleware).length
    },
    podsWithVideo() {
      return this.pods.filter(pod => pod.video)
    },
    isVerticalScreen() {
      return localStorage.getItem('is-vertical') === 'true'
    },
    podsLength() {
      return this.pods.length
    },
    gridLength() {
      if (this.podsLength == 1) return 1
      if (this.podsLength == 2) return 2
      if (this.podsLength <= 4) return 4
      if (this.podsLength <= 6) return 6
      if (this.podsLength <= 8) return 8
      return 12
    },
    gridCols() {
      if (this.isVerticalScreen) {
        if (this.podsLength <= 2) return 1
        if (this.podsLength <= 4) return 2
        if (this.podsLength <= 8) return 2
        return 3
      }
      if (this.gridLength == 1) return 1
      if (this.gridLength == 2) return 2
      return Math.ceil(this.gridLength / 2)
    },
    gridRows() {
      if (this.isVerticalScreen) {
        if (this.gridLength == 1) return 1
        if (this.gridLength == 2) return 2
        return Math.ceil(this.gridLength / 2)
      }
      if (this.podsLength <= 2) return 1
      if (this.podsLength <= 4) return 2
      if (this.podsLength <= 8) return 2
      return 3
    }
  },

  created() {
    setTimeout(() => {
      document.querySelectorAll('.empty-room svg.animate-bounce').forEach(element => {
        element.classList.add('opacity-70')
        element.classList.remove('animate-bounce')
      })
    }, 15000)
  },

  methods: {
    togglePodVideo(pod) {
      if (pod.video) this.$emit('enable-stream', pod.video, !pod.video.enabled)
    },
    toggleFullscreen(pod) {
      this.$emit('toggle-fullscreen', pod)
    },
    muteAttendee(attendee) {
      this.$emit('mute-attendee', attendee)
    },
    kickAttendee(attendee) {
      this.$emit('kick-attendee', attendee)
    },
    togglePromote(attendee, noModal, promote) {
      this.$emit('toggle-promote', attendee, noModal, promote)
    },
    goToUserChat(user) {
      this.$emit('go-to-user-chat', user)
    }
  }
}
</script>

<style lang="scss">
.pods-wrapper {
  &.is-floating {
    @apply flex justify-end items-end;

    .pods-grid {
      @apply flex items-center h-full overflow-x-auto max-w-full content-end mb-4;

      .remote-pod {
        &:not(.fullscreen-on) {
          @apply w-36 h-24 invisible z-0;
        }
      }
    }

    &.is-floating-visible {
      .pods-grid {
        .remote-pod {
          &:not(.fullscreen-on) {
            @apply visible z-20;
          }
        }
      }
    }
  }
}
</style>
