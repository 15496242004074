<template>
  <div class="flex flex-1">
    <div class="w-[24rem] flex flex-col gap-3 px-5 py-10">
      <div class="flex items-center justify-center my-5">
        <fw-icon-uc-digital-building class="w-40 h-40 text-white " />
      </div>
      <div
        v-if="currentHour"
        class="flex flex-col pl-2 gap-1 items-center text-center whitespace-nowrap text-7xl font-bold"
      >
        <div class="opacity-30 text-2xl">Hora atual</div>
        <div class="flex gap-1 font-bold items-center text-8xl">
          <span>{{ currentHour }}</span>
          <span class="animate-pulse">:</span>
          <span>{{ currentMinutes }}</span>
        </div>
      </div>
      <div class="flex flex-col gap-1 text-center">
        <div class="opacity-30 text-2xl font-bold">ID da sala</div>
        <v-clamp autoresize :max-lines="1" class="font-mono text-4xl font-semibold">
          {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
        </v-clamp>
        <v-clamp v-if="false" autoresize :max-lines="2" class="text-2xl font-semibold">
          {{ meetingTitle }}
        </v-clamp>
        <v-clamp v-if="false && meeting.title != meetingTitle" autoresize :max-lines="1" class="text-xl font-bold">
          {{ meeting.title }}
        </v-clamp>
      </div>
      <div class="flex-1"></div>
      <div
        v-if="false && duration"
        class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-7xl font-bold"
      >
        <div class="opacity-30 text-xl">Tempo em sessão</div>
        {{ duration }}
        <div class="font-medium text-xl">Iniciou às {{ meetingStartTime }}</div>
      </div>
      <div
        class="rounded-xl flex flex-col justify-center gap-1 p-5 text-center w-full relative"
        :class="{
          'bg-primary': handsupUnatended > 0,
          'bg-gray-800 opacity-20': handsupUnatended == 0
        }"
      >
        <fw-icon-hand-raised2 class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-8xl font-black">
          {{ handsupUnatended }}
        </div>
        <div class="text-3xl font-bold text-center mx-auto max-w-[14rem]">A pedir a palavra</div>
      </div>
      <div
        class="rounded-xl flex flex-col justify-center gap-1 p-5 text-center relative w-full"
        :class="{
          'bg-primary animate-pulse': askingToEnter > 0,
          'bg-gray-800 opacity-20': askingToEnter === 0
        }"
      >
        <fw-icon-door class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-8xl font-black">
          {{ askingToEnter }}
        </div>
        <div class="text-3xl font-bold text-center mx-auto max-w-[14rem]">A pedir para entrar</div>
      </div>
      <div class="rounded-xl flex flex-col justify-center gap-1 p-5 text-center relative w-full">
        <fw-icon-map-pin-user class="w-[4rem] h-[4rem] absolute top-5 left-5" />
        <div class="text-8xl font-black">{{ numberAttendes }}</div>
        <div class="text-3xl font-bold text-center">Online</div>
      </div>
    </div>
    <div class="relative flex-1 bg-black flex flex-col">
      <div class="w-full relative flex-1">
        <div class="absolute z-50 top-5 text-5xl font-bold right-5 bg-white bg-opacity-20 px-5 py-4 rounded-xl">
          <span>Live</span>
          <span class="bg-white animate-pulse h-4 w-4 absolute top-3 right-3 rounded-full"></span>
        </div>
        <BlockRemoteStreamScreen
          v-if="myPod"
          :key="myPod.key"
          :main="true"
          :ignore-fullscreen="true"
          :pod="myPod"
          :pods-length="1"
          :attendee="attendee"
          :with-fullscreen="true"
          :as-webinar="asWebinar"
          :fill-pod="true"
          :borderless="true"
        />
      </div>
      <div class="gap-5 grid grid-cols-4 p-5">
        <div
          class="flex flex-col items-center gap-3 justify-center text-center rounded-2xl bg-gray-700 bg-opacity-20 p-3"
        >
          <div class="text-base text-white opacity-30">Vídeo</div>
          <div
            class="flex gap-3 items-center text-5xl font-extrabold"
            :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }"
          >
            <fw-icon-vidicon-solid class="w-12 h-12 flex-shrink-0" />
          </div>
          <div class="text-base font-semibold" :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }">
            {{ withVideo ? 'Ligado' : 'Desligado' }}
          </div>
        </div>
        <div
          class="flex flex-col items-center gap-3 justify-center text-center rounded-2xl bg-gray-700 bg-opacity-20 p-3"
        >
          <div class="text-base text-white opacity-30">Microfone</div>
          <div
            class="flex gap-3 items-center text-5xl font-extrabold"
            :class="{ 'text-primary': withMic, 'text-red-600': !withMic }"
          >
            <fw-icon-mic v-if="withMic" class="w-12 h-12 flex-shrink-0" />
            <fw-icon-mic-off v-else class="w-12 h-12 flex-shrink-0" />
          </div>
          <div class="text-base font-semibold" :class="{ 'text-primary': withMic, 'text-red-600': !withMic }">
            {{ withMic ? 'Ligado' : 'Desligado' }}
          </div>
        </div>
        <div
          class="flex flex-col items-center gap-3 justify-center text-center rounded-2xl bg-gray-700 bg-opacity-20 p-3"
        >
          <div class="text-base text-white opacity-30">MONITOR 1</div>
          <div
            class="flex gap-3 items-center text-5xl font-extrabold"
            :class="{ 'text-primary': screen1, 'opacity-50': !screen1 }"
          >
            <fw-icon-screen class="w-12 h-12 flex-shrink-0" />
            1
          </div>
          <div class="text-base font-semibold" :class="{ 'text-primary': screen1, ' text-white opacity-30': !screen1 }">
            {{ screen1 ? 'A partilhar' : 'Não partilhado' }}
          </div>
        </div>
        <div
          class="flex flex-col items-center gap-3 justify-center text-center rounded-2xl bg-gray-700 bg-opacity-20 p-3"
        >
          <div class="text-base text-white opacity-30">MONITOR 2</div>
          <div
            class="flex gap-3 items-center text-5xl font-extrabold"
            :class="{ 'text-primary': screen2, 'opacity-50': !screen2 }"
          >
            <fw-icon-screen class="w-12 h-12 flex-shrink-0" />
            2
          </div>
          <div class="text-base font-semibold" :class="{ 'text-primary': screen2, ' text-white opacity-30': !screen2 }">
            {{ screen2 ? 'A partilhar' : 'Não partilhado' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockRemoteStreamScreen from '@/components/blocks/BlockRemoteStreamScreen'

export default {
  name: 'PanelMeetingInfo',
  components: {
    BlockRemoteStreamScreen
  },
  props: {
    activePods: {
      type: Array,
      default: () => []
    },
    pods: {
      type: Array,
      default: () => []
    },
    meeting: {
      type: Object,
      default: () => {}
    },
    attendees: {
      type: Object,
      default: () => {}
    },
    waitingAttendees: {
      type: Array,
      default: () => []
    },
    attendeesHandsUp: {
      type: Array,
      default: () => []
    },
    attendee: {
      type: Object
    },
    asWebinar: {
      type: Boolean,
      default: false
    },
    duration: {
      type: String,
      default: '--:--:--'
    },
    isClassEdition: {
      type: Boolean,
      default: false
    },
    freeMicrophones: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      meetingStartTime: '--:--',
      currentHour: null,
      currentMinutes: null,
      maxHeightVideo: '740'
    }
  },
  computed: {
    withMic() {
      if (this.myPod) {
        return this.myPod.withAudio
      } else {
        return false
      }
    },
    withVideo() {
      if (this.myPod) {
        return this.myPod.video != null
      } else {
        return false
      }
    },
    meetingTitle() {
      return !this.isClassEdition ? this.meeting.title : this.meeting.context.unit.title
    },
    askingToEnter() {
      return this.waitingAttendees.length
    },
    numberAttendes() {
      // Do not count the host
      return Math.max(0, this.pods.filter(i => i.type == 'attendee' && !i.isUcMiddleware).length - 1)
    },
    attendeesWithaudioPermission() {
      return this.attendeesHandsupHidrated.filter(attendee => attendee.withRole('audio_allowed')).length
    },
    handsupUnatended() {
      return this.attendeesHandsUp.length
    },
    attendeesHandsupHidrated() {
      return this.attendeesHandsUp.map(attendeeId => {
        return this.attendees[attendeeId]
      })
    },
    myPod() {
      return this.activePods.length > 0 ? this.activePods[0] : null
    },
    screenPods() {
      return this.pods.filter(pod => pod.type == 'screen_share')
    },
    screenPodsLenght() {
      return this.screenPods.length
    },
    screen1() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('primário') ||
            element.name.toLowerCase().includes('primary') ||
            element.name.toLowerCase().includes('principal')
        ) != 'undefined'
      )
    },
    screen2() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('secundário') || element.name.toLowerCase().includes('secondary')
        ) != 'undefined'
      )
    }
  },
  created() {
    // this.maxHeightVideo = window.innerHeight - 340
  },
  mounted() {
    this.startClock()
  },
  methods: {
    updateTime() {
      let date = new Date()
      this.currentHour = date.toLocaleTimeString('pt-PT', {
        hour: '2-digit'
      })
      this.currentMinutes = this.checkSingleDigit(
        date.toLocaleTimeString('pt-PT', {
          minute: '2-digit'
        })
      )
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
    startClock() {
      this.updateTime()
      if (this.meetingStartTime == '--:--') this.meetingStartTime = this.currentHour + ':' + this.currentMinutes
      setInterval(() => {
        this.updateTime()
      }, 1000)
    }
  }
}
</script>
