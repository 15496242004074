import store from '@/store'
import ViewLive from '@/views/LiveUnit'
import ViewMeetingRoom from '@/views/ViewMeetingRoom'

export default [
  {
    path: '/live/:key',
    name: 'live',
    component: ViewLive,
    meta: {
      requiresRole: 'user',
      allowPlugin: true,
      redirect: async function(route, isLoggedIn) {
        if (!isLoggedIn) {
          return { name: 'login' }
        }
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      redirect: async function(route, isLoggedIn) {
        if (isLoggedIn) {
          store.dispatch('logout')
        } else {
          return { name: 'meetingRoom' }
        }
      }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/fw-modules/fw-core-vue/id/views/Logout'),
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'meetingRoom',
    component: ViewMeetingRoom,
    meta: {
      redirect: async function(route, isLoggedIn) {
        if (isLoggedIn) {
          store.dispatch('logout')
        }
      }
    }
  }
]
