const en = {
  app: {
    description: 'Jobs at University of Coimbra',
    client: 'University of Coimbra'
  },
  message: {
    hello: 'Hello world',
    internalErrorFound: 'Ocorreu um erro interno...',
    login: {
      loginFailed: 'Wrong password. Try again or click Forgot password to reset it.'
    },
    form: {
      errorsFound: 'O formulário contém erros.',
      required: 'Required',
      emailRequired: 'Indique um endereço de email válido.',
      passwordRequired: 'Introduza a password.'
    }
  },
  summary: {
    recoverPassword: 'Enter your email address',
    login: ''
  },
  error: {
    notfound: {
      title: 'Not found',
      description: 'The resource requested could not be found.'
    }
  },
  title: {
    login: 'Login',
    welcome: 'Welcome',
    account: 'Account',
    notifications: 'Notifications',
    balance: 'Balance',
    invoices: 'Invoices',
    mb: 'MB Payment',
    credentials: 'Credentials',
    transfer: 'Credit Transfer',
    refund: 'Refund',
    features: 'Funcionalidades',
    recoverPassword: 'Recuperar conta'
  },
  link: {
    home: 'Home',
    next: 'Next',
    login: 'Login',
    registerAccount: 'Register account',
    recoverPassword: 'Esqueceu-se da password?',
    balance: 'Balanço',
    credentials: '@:title.credentials',
    transfer: '@:title.transfer',
    invoices: '@:title.invoices',
    mb: 'MB',
    refund: '@:title.refund',
    anotherAccount: 'Other account?'
  }
}

export default en
