<template>
  <div class="absolute top-0 left-0 right-0 min-h-40 z-40 flex overflow-hidden" style="height: 640px;">
    <div class="flex flex-col gap-4 items-center py-4 pr-4 pl-1 w-96">
      <div class="px-0 flex flex-col items-center pt-3 pl-3 justify-around gap-10 flex-1 w-full">
        <DigitalDesk class="h-5" />
        <div
          v-if="currentHour"
          class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-5xl font-bold"
        >
          <div class="flex font-bold items-center text-5xl">
            <fw-icon-clock-line class="w-11 h-11 mr-2" />
            {{ currentHour }}
            <span class="animate-pulse">:</span>
            {{ currentMinutes }}
          </div>
        </div>
        <div class="flex flex-col gap-4 text-center">
          <v-clamp autoresize :max-lines="1" class="font-mono opacity-70 text-4xl font-semibold">
            {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
          </v-clamp>
        </div>
        <div
          v-if="duration"
          class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-5xl font-bold"
        >
          <div class="opacity-30 text-lg">Tempo em sessão</div>
          {{ duration }}
          <div class="font-medium text-lg">Iniciou às {{ meetingStartTime }}</div>
        </div>
        <div class="p-3 w-full gap-3 bg-panel rounded-2xl grid grid-cols-2 grid-rows-2">
          <div class="flex flex-col items-center gap-2 p-3 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">Vídeo</div>
            <div
              class="flex gap-3 items-center text-5xl font-extrabold"
              :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }"
            >
              <fw-icon-vidicon-solid class="w-8 h-8 flex-shrink-0" />
            </div>
            <div class="text-base font-semibold" :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }">
              {{ withVideo ? 'Ligado' : 'Desligado' }}
            </div>
          </div>
          <div class="flex flex-col items-center gap-2 p-3 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">Microfone</div>
            <div
              class="flex gap-3 items-center text-5xl font-extrabold"
              :class="{ 'text-primary': withMic, 'text-red-600': !withMic }"
            >
              <fw-icon-mic v-if="withMic" class="w-8 h-8 flex-shrink-0" />
              <fw-icon-mic-off v-else class="w-8 h-8 flex-shrink-0" />
            </div>
            <div class="text-base font-semibold" :class="{ 'text-primary': withMic, 'text-red-600': !withMic }">
              {{ withMic ? 'Ligado' : 'Desligado' }}
            </div>
          </div>
          <div class="flex flex-col items-center gap-2 p-3 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">MONITOR 1</div>
            <div
              class="flex gap-3 items-center text-4xl font-extrabold"
              :class="{ 'text-primary': screen1, 'opacity-50': !screen1 }"
            >
              <fw-icon-screen class="w-8 h-8 flex-shrink-0" />
              1
            </div>
            <div
              class="text-base font-semibold"
              :class="{ 'text-primary': screen1, ' text-white opacity-30': !screen1 }"
            >
              {{ screen1 ? 'A partilhar' : 'Não partilhado' }}
            </div>
          </div>
          <div class="flex flex-col items-center gap-2 p-3 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">MONITOR 2</div>
            <div
              class="flex gap-3 items-center text-4xl font-extrabold"
              :class="{ 'text-primary': screen2, 'opacity-50': !screen2 }"
            >
              <fw-icon-screen class="w-8 h-8 flex-shrink-0" />
              2
            </div>
            <div
              class="text-base font-semibold"
              :class="{ 'text-primary': screen2, ' text-white opacity-30': !screen2 }"
            >
              {{ screen2 ? 'A partilhar' : 'Não partilhado' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4 items-center py-4 pr-4 pl-1 w-80">
      <div
        class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full flex-1 relative"
        :class="{
          'bg-primary': handsupUnatended > 0,
          'bg-gray-800': handsupUnatended == 0
        }"
      >
        <fw-icon-hand-raised2 class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-7xl font-black">
          {{ handsupUnatended }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas a pedir a palavra</div>
      </div>
      <div
        class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full flex-1 relative"
        :class="{
          'bg-primary animate-pulse': askingToEnter > 0,
          'bg-gray-800 opacity-20': askingToEnter === 0
        }"
      >
        <fw-icon-door class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-7xl font-black">
          {{ askingToEnter }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas a pedir para entrar</div>
      </div>
      <div
        class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full flex-1 bg-gray-800 relative"
      >
        <fw-icon-map-pin-user class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-7xl font-black">
          {{ numberAttendes }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas online</div>
      </div>
      <div
        v-if="totalScreenPods.length > 1"
        class="rounded-xl px-5 py-3 flex w-full items-center gap-5 bottom-0 text-lg right-0 bg-yellow-400 text-black font-bold"
      >
        <svg class="h-10 w-10 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
          ></path>
        </svg>
        {{ totalScreenPods.length }} partilhas remotas
      </div>
    </div>
    <div style="margin-top: 16px;" :style="{ width: maxHeightVideo * 1.778 + 'px' }">
      <BlockRemoteStreamScreen
        v-if="myPod"
        :key="myPod.key"
        :main="true"
        :pod="myPod"
        :pods-length="1"
        :attendee="attendee"
        :with-fullscreen="false"
        :as-webinar="asWebinar"
        :fill-pod="true"
        :borderless="true"
        :style="{ maxHeight: maxHeightVideo + 'px' }"
      />
    </div>
    <div ref="share-screen-container" class="flex-1 flex flex-col overflow-hidden flex-shrink-0 relative">
      <div
        class="absolute justify-start items-start left-5 top-4 bottom-4 right-5 grid"
        :class="{
          [gridClass]: !isSharingScreen
        }"
      >
        <div
          v-for="pod in pageActivePods"
          :key="pod.id"
          class="overflow-hidden w-full"
          :style="{ maxHeight: maxPodHeight, height: height }"
        >
          <BlockRemoteStreamScreen
            :key="pod.id"
            :pod="pod"
            :pods-length="podsLength"
            :attendee="attendee"
            :with-fullscreen="false"
            :as-webinar="false"
            :fill-pod="true"
            :size="isSharingScreen ? 'xs' : 'md-2'"
            :show-name="!isSharingScreen"
            :show-hd="false"
            :show-guest-info="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DigitalDesk from '@/components/DigitalDesk'
import BlockRemoteStreamScreen from '@/components/blocks/BlockRemoteStreamScreen'
export default {
  name: 'PanelScreenShare',
  components: {
    BlockRemoteStreamScreen,
    DigitalDesk
  },
  props: {
    asWebinar: Boolean,
    pods: {
      type: Array,
      default: () => []
    },
    activePods: {
      type: Array,
      default: () => []
    },
    attendee: {
      type: Object
    },
    meeting: {
      type: Object
    },
    waitingAttendees: {
      type: Array,
      default: () => []
    },
    attendeesHandsUp: {
      type: Array,
      default: () => []
    },
    duration: {
      type: String,
      default: '--:--:--'
    }
  },
  data() {
    return {
      meetingStartTime: '--:--',
      currentHour: null,
      currentMinutes: null,
      screenHeight: 650,
      maxHeightVideo: 528
    }
  },
  computed: {
    maxPodHeight() {
      return window.innerHeight / this.gridRows + 'px'
    },
    height() {
      return this.maxPodHeight
    },
    gridClass() {
      return 'grid-cols-' + this.gridCols + ' grid-rows-' + this.gridRows
    },
    activeScreenPod() {
      return null
      /* const firstPod = this.activePods[0]
      if (firstPod && firstPod.type === 'screen_share' && !firstPod.isUcMiddleware) {
        return firstPod
      }
      return null*/
    },
    isSharingScreen() {
      return false
      //return !!this.activeScreenPod
    },
    pageActivePods() {
      return this.activePods.slice(1)
      /* if (this.isSharingScreen || (this.activePods.length > 0 && this.activePods[0].isUcMiddleware)) {
        return this.activePods.slice(1)
      }
      return this.activePods */
    },
    podsLength() {
      return this.pageActivePods.length //this.activePods.length
    },
    gridCols() {
      return 6
      //if (this.isSharingScreen) return 12
      //if (this.podsLength == 1) return 1
      //if (this.podsLength <= 4) return 2
      //if (this.podsLength <= 6) return 3
      //if (this.podsLength <= 8) return 4
      //if (this.podsLength <= 12) return 4
      //if (this.podsLength <= 15) return 5
      //if (this.podsLength <= 20) return 5
      //return 6
    },
    gridRows() {
      return 3
      //if (this.isSharingScreen) return 1
      //if (this.podsLength <= 2) return 1
      //if (this.podsLength <= 6) return 2
      //if (this.podsLength <= 8) return 2
      //if (this.podsLength <= 12) return 3
      //if (this.podsLength <= 15) return 3
      //if (this.podsLength <= 20) return 4
      //return 4
    },
    totalScreenPods() {
      return this.pods.filter(pod => pod.type == 'screen_share' && !pod.isUcMiddleware)
    },
    askingToEnter() {
      return this.waitingAttendees.length
    },
    numberAttendes() {
      return this.pods.filter(i => i.type == 'attendee' && !i.isUcMiddleware).length
    },
    attendeesWithaudioPermission() {
      return this.attendeesHandsupHidrated.filter(attendee => attendee.withRole('audio_allowed')).length
    },
    handsupUnatended() {
      return this.attendeesHandsUp.length
    },
    attendeesHandsupHidrated() {
      return this.attendeesHandsUp.map(attendeeId => {
        return this.attendees[attendeeId]
      })
    },
    myPod() {
      return this.activePods.length > 0 ? this.activePods[0] : null
    },
    screenPods() {
      return this.pods.filter(pod => pod.type == 'screen_share')
    },
    screenPodsLenght() {
      return this.screenPods.length
    },
    screen1() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('primário') ||
            element.name.toLowerCase().includes('primary') ||
            element.name.toLowerCase().includes('principal')
        ) != 'undefined'
      )
    },
    screen2() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('secundário') || element.name.toLowerCase().includes('secondary')
        ) != 'undefined'
      )
    },
    withMic() {
      if (this.myPod) {
        return this.myPod.withAudio
      } else {
        return false
      }
    },
    withVideo() {
      if (this.myPod) {
        return this.myPod.video != null
      } else {
        return false
      }
    }
  },
  created() {
    //this.maxHeightVideo = window.innerHeight - 32
    this.maxHeightVideo = 640 - 32
  },
  mounted() {
    this.startClock()
    this.calculateScreenShareHeight()
    //listen to windows resize
    window.addEventListener('resize', this.calculateScreenShareHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateScreenShareHeight)
  },
  methods: {
    calculateScreenShareHeight() {
      let containerWidth = this.$refs['share-screen-container'].offsetWidth
      this.screenHeight = containerWidth / (16 / 9) + 60
    },
    updateTime() {
      let date = new Date()
      this.currentHour = date.toLocaleTimeString('pt-PT', {
        hour: '2-digit'
      })
      this.currentMinutes = this.checkSingleDigit(
        date.toLocaleTimeString('pt-PT', {
          minute: '2-digit'
        })
      )
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
    startClock() {
      this.updateTime()
      if (this.meetingStartTime == '--:--') this.meetingStartTime = this.currentHour + ':' + this.currentMinutes
      setInterval(() => {
        this.updateTime()
      }, 1000)
    }
  }
}
</script>
